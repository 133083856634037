@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");

.inter {
  font-family: Inter;
  font-style: normal;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  text-transform: none;
}

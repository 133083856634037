@import "rsuite/dist/rsuite.css";

:root {
  --rs-bg-active: var(--ion-color-primary) !important;
  --rs-btn-link-text: var(--ion-color-primary) !important;
  --rs-btn-primary-bg: var(--ion-color-primary) !important;
  --rs-input-focus-border: var(--ion-color-primary) !important;
}

.rs-modal-content {
  padding: 32px;
}

.rs-picker-toggle.rs-btn.rs-btn-subtle {
  padding-right: 12px !important;
}

.rs-picker-toggle.rs-btn.rs-btn-subtle > .rs-stack {
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 640px) {
  .rs-picker-toggle.rs-btn.rs-btn-subtle
    > .rs-stack
    > .rs-stack-item:first-child {
    display: none;
  }
  .rs-picker-toggle.rs-btn.rs-btn-subtle
    > .rs-stack
    > .rs-stack-item:last-child
    > div {
    margin-right: 0px !important;
  }
}

.rs-radio-checked .rs-radio-inner::before {
  background-color: white !important;
  border: 2px solid var(--ion-color-primary) !important;
}

.rs-radio-inner::before {
  background-color: white !important;
  border: 2px solid var(--ion-color-noytrall_secondary) !important;
}
.rs-radio-inner::after {
  background-color: var(--ion-color-primary) !important;
}

/* RSUITE */
.rs-picker {
  --rs-picker-value: var(--ion-color-secondary) !important;
}

.rs-picker > div {
  height: 56px !important;
  display: flex !important;
  align-items: center !important;
  padding: 7px 11px !important;
  width: 100%;
}

.rs-picker > div > div {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 100%;
}

/* Clear button */
.rs-picker > div > div > div:last-child > span {
  position: static !important;
}

/* Arrow down */
.rs-picker > div > div > div:last-child > svg {
  position: static !important;
}

.date_range_select {
  --rs-picker-value: var(--ion-color-noytrall_secondary) !important;
}

.date_range_select,
.date_range_select > div {
  height: 56px !important;
  width: 100% !important;
}

.date_range_select > div {
  display: flex !important;
  align-items: center !important;

  padding-right: 11px !important;
}

.date_range_select > div > div {
  display: flex !important ;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
}

.date_range_select > div > div > div:last-child > svg {
  position: static !important;
}

.rs-picker-value-count {
  display: none;
}

/* NUMBER INPUT */
.rs-input-group-addon {
  height: 56px !important;
}

/* PROGRESS */
.rs-progress-line {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rs-progress-line > div > div > div,
.rs-progress-line > div > div {
  height: 2px !important;
}

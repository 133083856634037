/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /* primary */
  --ion-color-primary: #40bfb4;
  --ion-color-primary-rgb: 64, 191, 180;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #38a89e;
  --ion-color-primary-tint: #53c5bc;

  /* white */
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  /* anti_flash_white */
  --ion-color-anti_flash_white: #f0f0f0;
  --ion-color-anti_flash_white-rgb: 240, 240, 240;
  --ion-color-anti_flash_white-contrast: #000000;
  --ion-color-anti_flash_white-contrast-rgb: 0, 0, 0;
  --ion-color-anti_flash_white-shade: #d3d3d3;
  --ion-color-anti_flash_white-tint: #f2f2f2;

  /* black */
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  /* noytrall_secondary */
  --ion-color-noytrall_secondary: #333e48;
  --ion-color-noytrall_secondary-rgb: 51, 62, 72;
  --ion-color-noytrall_secondary-contrast: #ffffff;
  --ion-color-noytrall_secondary-contrast-rgb: 255, 255, 255;
  --ion-color-noytrall_secondary-shade: #2d373f;
  --ion-color-noytrall_secondary-tint: #47515a;

  /** red **/
  --ion-color-red: #eb5757;
  --ion-color-red-rgb: 235, 87, 87;
  --ion-color-red-contrast: #000000;
  --ion-color-red-contrast-rgb: 0, 0, 0;
  --ion-color-red-shade: #cf4d4d;
  --ion-color-red-tint: #ed6868;

  /** yellow **/
  --ion-color-yellow: #f2c94c;
  --ion-color-yellow-rgb: 242, 201, 76;
  --ion-color-yellow-contrast: #000000;
  --ion-color-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-shade: #d5b143;
  --ion-color-yellow-tint: #f3ce5e;

  /** gray4 **/
  --ion-color-gray4: #bdbdbd;
  --ion-color-gray4-rgb: 189, 189, 189;
  --ion-color-gray4-contrast: #000000;
  --ion-color-gray4-contrast-rgb: 0, 0, 0;
  --ion-color-gray4-shade: #a6a6a6;
  --ion-color-gray4-tint: #c4c4c4;

  /** gray5 **/
  --ion-color-gray5: #e0e0e0;
  --ion-color-gray5-rgb: 224, 224, 224;
  --ion-color-gray5-contrast: #000000;
  --ion-color-gray5-contrast-rgb: 0, 0, 0;
  --ion-color-gray5-shade: #c5c5c5;
  --ion-color-gray5-tint: #e3e3e3;
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-anti_flash_white {
  --ion-color-base: var(--ion-color-anti_flash_white);
  --ion-color-base-rgb: var(--ion-color-anti_flash_white-rgb);
  --ion-color-contrast: var(--ion-color-anti_flash_white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-anti_flash_white-contrast-rgb);
  --ion-color-shade: var(--ion-color-anti_flash_white-shade);
  --ion-color-tint: var(--ion-color-anti_flash_white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-noytrall_secondary {
  --ion-color-base: var(--ion-color-noytrall_secondary);
  --ion-color-base-rgb: var(--ion-color-noytrall_secondary-rgb);
  --ion-color-contrast: var(--ion-color-noytrall_secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-noytrall_secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-noytrall_secondary-shade);
  --ion-color-tint: var(--ion-color-noytrall_secondary-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}
.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-gray4 {
  --ion-color-base: var(--ion-color-gray4);
  --ion-color-base-rgb: var(--ion-color-gray4-rgb);
  --ion-color-contrast: var(--ion-color-gray4-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray4-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray4-shade);
  --ion-color-tint: var(--ion-color-gray4-tint);
}

.ion-color-gray5 {
  --ion-color-base: var(--ion-color-gray5);
  --ion-color-base-rgb: var(--ion-color-gray5-rgb);
  --ion-color-contrast: var(--ion-color-gray5-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray5-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray5-shade);
  --ion-color-tint: var(--ion-color-gray5-tint);
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
